import * as React from 'react'
import Layout from '../../components/layout'

import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const BlogPage = ({ data }) => {
    return (
        <Layout pageTitle='My Blog Posts'>
            {
                data.allMdx.nodes.map(node => (
                    <article key={node.id}>
                        <h2><Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link></h2>
                        <p>Posted: {node.frontmatter.date}</p>
                    </article>
                ))
            }
      <button className="block uppercase mx-auto shadow bg-indigo-800">Tailwind Test</button>
        </Layout>
    )
}

export const query = graphql`
    query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                frontmatter {
                    title
                    date(formatString: "MMMM D, YYYY")
                }
                id
                slug
            }
        }
    }
`

export default BlogPage;
